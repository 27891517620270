import gql from 'graphql-tag';

export const GET_CMS_PAGE = gql`
  query CmsPage($id: String!, $siteId: String) {
    cms {
      page(id: $id, siteId: $siteId) {
        documentTitle
        metaTags {
          name
          content
        }
        breadcrumbs {
          name
          url
        }
        htmlContent
        source
      }
    }
  }
`;
